import "core-js/features/promise";
import { Renderer } from "globals";

const appPromise = import(/* webpackChunkName: "app" */ "./app");

const polyfills: Promise<unknown>[] = [];

if (!window.fetch) {
  import(/* webpackChunkName: "polyfill-fetch" */ "whatwg-fetch").then(
    ({ fetch }) => {
      polyfills.push(fetch);
      window.fetch = fetch;
    }
  );
}

Promise.all(polyfills).then(() => {
  const windowRender: Renderer = ({
    aspectRatio,
    element,
    programId,
    captions,
    language,
    loop,
    muted
  }) =>
    appPromise.then(app =>
      app.render({
        aspectRatio,
        element,
        programId,
        captions,
        language,
        loop,
        muted
      })
    );

  if (!window.yleSimplePlayer) {
    window.yleSimplePlayer = { render: windowRender };
  }
});
